import { isCookiesAuthEnabled } from "@/helpers/cookies"

export const getTenantFromPath = function (): string {
  const path = window.location.pathname || '/'
  return path.split('/')[1]
}

export const getTenantFromCookie = function (): string {
  const name = 'tenant='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieSplit = decodedCookie.split(';')

  for (let i = 0; i < cookieSplit.length; i++) {
    const cookieValue = cookieSplit[i].trim()
    if (cookieValue.indexOf(name) === 0) {
      return cookieValue.substring(name.length, cookieValue.length)
    }
  }

  return ''
}

export const getTenant = function (): string {
  return getTenantFromCookie() || getTenantFromPath()
}

export const getCookieStorageDomain = function (): string {
  if (window.location.hostname === 'localhost') {
    return 'localhost'
  }

  return process.env.VUE_APP_COOKIE_STORAGE_DOMAIN || '.truora.com'
}

export const delTenantFromCookie = (): void => {
  document.cookie = `tenant=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${getCookieStorageDomain()}; path=/;`
}

const TENANT = getTenant()

const COGNITO_CONFIG_KEY = `awsCognitoConfig:${TENANT}`
export { TENANT, COGNITO_CONFIG_KEY };

export const BASE_API =
  (isCookiesAuthEnabled()
    ? window.location.origin + process.env.VUE_APP_VALIDATIONS_API_PATH
    : process.env.VUE_APP_BASE_API) || "https://api.validations.truora.com";

export const ACCOUNTS_BASE_API =
  (isCookiesAuthEnabled()
    ? window.location.origin + process.env.VUE_APP_ACCOUNT_API_PATH
    : process.env.VUE_APP_ACCOUNTS_BASE_API) || "https://api.account.truora.com";

export const CHECKS_BASE_API =
  (isCookiesAuthEnabled()
    ? window.location.origin + process.env.VUE_APP_CHECKS_API_PATH
    : process.env.VUE_APP_CHECKS_BASE_API) || "https://api.checks.truora.com";

export const ACCOUNTS_BASE = process.env.VUE_APP_ACCOUNTS_BASE || "http://account.truora.com";
export const ACCOUNTS_LOGOUT = process.env.VUE_APP_ACCOUNT_LOGOUT_PATH || "https://account.truora.com/#/logout"
export const CHECKS_DASHBOARD = process.env.VUE_APP_CHECKS_DASHBOARD || "https://checks.truora.com";

export const TRUORA_WEB_CLIENT = 'webapp'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const TRUORA_TEAM_TENANTS = ['truorateam', 'truorateamstaging', 'truorateamnewprod']

export const MANAGE_USER_TOKEN_ENDPOINT = "/v1/user/token";
export const SELECTED_ACCOUNT_COOKIE_NAME = "SelectedAccount";
export const IS_NEW_ACCOUNT_MODEL_ENABLED =
  process.env.VUE_APP_IS_NEW_ACCOUNT_MODEL_ENABLED === "true" || false;

  export const ZAPSIGN_SEND_FROM_TRUORA_URL =
  process.env.VUE_APP_ZAPSIGN_SEND_FROM_TRUORA_URL || "https://app.zapsign.com.br/acesso/send/truora"

// Language Constants

export const LANGUAGES = [
  { language: "EN", locale: "en", representation: "English" },
  { language: "ES", locale: "es", representation: "Español" },
  { language: "PT", locale: "pt", representation: "Português" },
]

// exported only for testing reasons, please always use the constant
export const shouldEnableTracking = () : boolean => {
  if (window.location.href.includes('localhost') || window.location.href.includes('staging')) {
    return false
  }

  const cookies = document.cookie.split(';')
  const tenantCookie = cookies.find((cookie) => cookie.trim().startsWith('tenant='))

  if (tenantCookie === undefined) {
    return true
  }

  const tenant = tenantCookie.split('=')[1]

  if (TRUORA_TEAM_TENANTS.includes(tenant)) {
    return false
  }

  return true
}

// Validations Google Analytics (GA) properties
export const VALIDATIONS_GA4_PROPERTY = 'G-1ST37ZL915'
export const VALIDATIONS_GA3_PROPERTY = 'UA-185981896-3'
export const ENABLE_TRACKING = shouldEnableTracking()
export const HOTJAR_ID = '2398312'
