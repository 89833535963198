import { DeclinedReason } from './declined_reason'

export enum ValidationTypes {
  PhoneVerification = 'phone-verification',
  EmailVerification = 'email-verification',
  DocumentValidation = 'document-validation',
  FaceRecognition = 'face-recognition',
  VoiceRecognition = 'voice-recognition',
  IdentityQuestions = 'identity-questions',
  EnterpriseData = 'enterprise-data',
  FaceSearch = 'face-search',
  ElectronicSignature = 'electronic-signature'
}

export enum ActionStatus {
  Success = 'success',
  Failure = 'failure',
  Pending = 'pending'
}

export enum ManualReviewStatus {
  Started   = 'started',
  Performed = 'performed'
}

export enum ValidationStatus {
  Success = 'success',
  Failure = 'failure',
  Pending = 'pending'
}

export enum FailureStatus {
  Declined = 'declined',
  SystemError = 'system_error',
  Expired = 'expired',
  Canceled = 'canceled'
}

export const ValidationPrefix = 'VLD'
export const AccountIDPrefix = 'ACC'

type ValidationInputs = {
  email?: string
  phone_number?: string
  phone_type?: string
  verify_channel?: string
  phone_locale?: string
  country?: string
  document_type?: string
  document_number?: string
  expiration_date?: Date
  liveness_actions?: string[]
  list_id?: string
  validation_input_files?: string[]
  questionnaire?: Record<string, string>[]
  language?: string
  phrase?: string
}

type UserResponse = {
  correct_questions?: string[]
  input_files?: string[]
}

export type BackgroundCheck = {
  check_id?: string,
}

export type Details = {
  background_check?: BackgroundCheck
}

export type Validation = {
  validation_id: string
  ip_address: string
  type: ValidationTypes
  validation_status: ValidationStatus
  document_expected_pages?: number
  threshold?: number
  failure_status?: FailureStatus
  declined_reason?: DeclinedReason
  validation_inputs?: ValidationInputs,
  details?: Details,
  user_response?: UserResponse
  creation_date: Date
  processing_start_date?: Date
  processing_finish_date?: Date
  account_id: string
}
